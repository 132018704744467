import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Sidebar from "../../../components/Sidebar";

import SwipeableTemporaryDrawer from "../../../components/Material/MaterialSidebar";
import { Skeleton } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import Breadcrumbs from "../../../components/Material/BreadCrumbs";
import SearchDropDown from "../../../components/Material/SearchDropDown";
import { GetExamConfig } from "../../../apis/fectcher/assessment/marksEntry/examConfig";

import Loader from "../../../components/Material/Loader";

import { GetSchoolDetailsWithoutHeader } from "../../../apis/fectcher/assessment/GetSchoolDetails";
import Cookies from "js-cookie";
import SchoolInfo from "../../../components/SchoolInfo";
import { useSearchParams } from "react-router-dom";
import { useLayoutEffect } from "react";
import Snackbars from "../../../components/Material/Snackbar";
import ControlledAutoComplete from "../../../components/Material/ControlledAutoComplete";
import BasicButton from "../../../components/Material/Button";
import instance from "../../../instance";
import { InputFileButton } from "../../../components/Material/FileButton";
import DialogSlide from "../../../components/Material/Dialog";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
const ExcelMarksEntry = () => {
  const [id, setId] = useState(null);
  const [grade, setGrade] = useState(null);
  const [section, setSection] = useState([]);
  const [subject, setSubject] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarErr, setSnackbarErr] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [queryParameters] = useSearchParams();
  const returnToken = () => {
    return queryParameters.get("auth");
  };

  useLayoutEffect(() => {
    if (queryParameters.get("auth")) {
      Cookies.set("token", queryParameters.get("auth"));
    }
  }, []);

  const { data: schoolInfo, isLoading: SchoolInfoLoading } = useQuery({
    queryKey: ["school_info"],
    queryFn: () => GetSchoolDetailsWithoutHeader(returnToken()),
  });

  const { data: examConfigData, isLoading: examConfigDataLoading } = useQuery({
    queryKey: ["exam_config"],
    queryFn: () => GetExamConfig(returnToken()),

    refetchOnWindowFocus: false,
  });

  const returnSubData = () => {
    let sectionsId = [];
    for (let i = 0; i < examConfigData.sections.length; i++) {
      const element = examConfigData.sections[i];
      if (section.includes(element[1])) {
        sectionsId.push(element[0]);
      }
    }
    let subjectSet = new Set();
    for (let i = 0; i < sectionsId.length; i++) {
      const element = sectionsId[i];
      for (
        let j = 0;
        j < examConfigData.sectionSubjectMap[element].length;
        j++
      ) {
        const element2 = examConfigData.sectionSubjectMap[element][j];
        subjectSet.add(element2.displayName);
      }
    }
    return Array.from(subjectSet);
  };

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const show = null;

  const sidebarRef = useRef();
  const handleDropDown = (value, type) => {
    // console.log(value);
    switch (type) {
      case "grade":
        setGrade(value);
        setSection([]);
        setSubject([]);
        break;
      case "section":
        if (value.length === 0) {
          setSubject([]);
        }
        setSection(value);
        break;
      case "subject":
        setSubject(value);
        break;
      case "exam":
        setId(value);
        break;

      default:
        break;
    }
  };

  const handleSidebarCollapsed = () => {
    sidebarRef.current.openSidebar();
  };

  const returnFormData = () => {
    let formData = new FormData();
    formData.append("examType", id.value);
    formData.append("grade", grade.id);
    let sectionsId = [];
    for (let i = 0; i < examConfigData.sections.length; i++) {
      const element = examConfigData.sections[i];
      if (section.includes(element[1])) {
        sectionsId.push(element[0]);
      }
    }
    formData.append("classSection", sectionsId.join(","));
    let subjectSet = new Set();
    for (let i = 0; i < sectionsId.length; i++) {
      const element = sectionsId[i];
      for (
        let j = 0;
        j < examConfigData.sectionSubjectMap[element].length;
        j++
      ) {
        const element2 = examConfigData.sectionSubjectMap[element][j];
        if (subject.includes(element2.displayName)) {
          subjectSet.add(element2.name);
        }
      }
    }
    formData.append("subject", Array.from(subjectSet).join(","));

    return formData;
  };

  const handleDownloadTemplate = async () => {
    let formData = returnFormData();
    setLoading(true);

    const res = await instance({
      url: `schoolApp/configuration/adminMarksEntryTemplate/download`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
      data: formData,
    }).catch((err) => {
      console.log(err);
      setSnackbarErr(true);
      setSnackbarMsg(err.response.data.message);
      snackbarRef.current.openSnackbar();
      setLoading(false);
    });
    if (res?.status === 200) {
      setSnackbarErr(false);
      setSnackbarMsg(res.data.message);
      window.open(res.data.url, "_blank");
      snackbarRef.current.openSnackbar();
    }
    setLoading(false);
  };

  const handleFileUpload = async () => {
    setLoading(true);
    let formData = returnFormData();
    formData.append("excelFile", file);
    const res = await instance({
      url: `schoolApp/configuration/adminMarksEntry/upload`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
      data: formData,
    }).catch((err) => {
      setModalMsg(err.response.data.message);
      setShowModal(true);
      setLoading(false);
    });
    if (res.status === 200) {
      setSnackbarErr(false);
      setSnackbarMsg(res.data.message);
      snackbarRef.current.openSnackbar();
    }
    setLoading(false);
  };

  const snackbarRef = useRef();
  const dialogRef = useRef();

  useEffect(() => {
    document.title = "Marks Entry Overview - ClassKlap";
    const handleWidth = () => {
      if (window.innerWidth > 1024) {
        setSidebarCollapsed(false);
      } else {
        setSidebarCollapsed(true);
      }
    };
    window.addEventListener("resize", handleWidth);
    handleWidth();
    window.scroll(0, 0);

    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={showModal}
        onClose={() => setShowModal(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <div className="p-4 md:p-5 text-center">
            <svg
              className="mx-auto  text-dark-400 w-10 h-10 dark:text-black-200"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="red"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </div>
          <Typography id="modal-desc" textColor="text.tertiary">
            {modalMsg}
          </Typography>
        </Sheet>
      </Modal>
      {/* {showModal && (
        <div
          id="popup-modal"
          className="fixed top-0 left-0 right-0 z-50 flex justify-center items-center w-full h-screen bg-gray-800 bg-opacity-75"
        >
          <div className="relative p-4 w-full max-w-md">
            <div className="relative bg-white rounded-lg shadow dark:bg-light-700">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-dark-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => setShowModal(false)}
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
              <div className="p-4 md:p-5 text-center">
                <svg
                  className="mx-auto mb-4 text-dark-400 w-12 h-12 dark:text-black-200"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <p className="mb-5  font-normal text-dark-500 dark:text-black-500">
                  {modalMsg}
                </p>
              </div>
            </div>
          </div>
        </div>
      )} */}

      <Snackbars
        ref={snackbarRef}
        message={snackbarMsg}
        snackbarErrStatus={snackbarErr}
      />
      <div className="flex w-[100%] min-h-[100vh]">
        <Sidebar
          highLight={""}
          sidebarCollapsed={sidebarCollapsed}
          show={show}
        />
        <Loader loading={loading} />
        <DialogSlide
          ref={dialogRef}
          text={"Do you want to upload the document ?"}
          buttonText={"Upload"}
          handleDialogButton={handleFileUpload}
        />

        <div>
          <SwipeableTemporaryDrawer
            ref={sidebarRef}
            sidebarCollapsed={sidebarCollapsed}
            show={show}
            highLight={""}
          />
        </div>

        <div
          className={`flex flex-col w-[100vw] bg-gray-200 relative transition-all overflow-hidden ease-linear duration-300 lg:w-[83vw] lg:ml-[18vw] ${
            window.innerWidth < 1024 ? null : "md:ml-[30vw] ml-[85vw]"
          } `}
        >
          <div
            className="lg:hidden absolute cursor-pointer top-4 left-4"
            onClick={handleSidebarCollapsed}
          >
            <Menu className={"text-[#67748e]"} />
          </div>
          <SchoolInfo
            SchoolInfoLoading={SchoolInfoLoading}
            schoolInfo={schoolInfo}
          />

          <div className="relative flex flex-col w-full justify-center items-start gap-4 bg-gray-200">
            <div className="sm:px-8 px-4 w-full flex flex-col gap-4 mb-4">
              <Breadcrumbs
                crumbs={[
                  "Home",
                  "Assessment",
                  "Marks Entry - Excel Marks Entry",
                ]}
              />
              <h1 className="font-bold sm:text-2xl text-base">
                Marks Entry - Excel Marks Entry
              </h1>

              <div className="w-full flex sm:flex-row flex-col gap-2 justify-between">
                {examConfigDataLoading ? (
                  <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{ fontSize: "2rem", width: "12rem" }}
                  />
                ) : (
                  <div className="sm:w-[40rem] w-[90vw] flex sm:flex-row flex-col gap-2">
                    <SearchDropDown
                      handleDropDown={handleDropDown}
                      data={examConfigData.exams.map((item) => {
                        return { value: item.name, name: item.displayName };
                      })}
                      variant={"outlined"}
                      Name={"exam"}
                      label={"Exam Type"}
                      size={"small"}
                    />
                    <SearchDropDown
                      handleDropDown={handleDropDown}
                      data={examConfigData.grades.map((item) => {
                        return { id: item.name, value: item.displayName };
                      })}
                      variant={"outlined"}
                      Name={"grade"}
                      label={"Grade"}
                      size={"small"}
                    />
                    <ControlledAutoComplete
                      handleDropDown={handleDropDown}
                      data={examConfigData.sections
                        .filter((item) => grade?.id === item[2])
                        .map((item) => {
                          return item[1];
                        })}
                      variant={"outlined"}
                      Name={"section"}
                      label={"Section"}
                      size={"small"}
                      multiline={true}
                      value={section}
                      disable={grade === null ? true : false}
                    />

                    <ControlledAutoComplete
                      handleDropDown={handleDropDown}
                      data={returnSubData()}
                      variant={"outlined"}
                      Name={"subject"}
                      label={"Subject"}
                      size={"small"}
                      multiline={true}
                      value={subject}
                      disable={section.length > 0 ? false : true}
                    />
                  </div>
                )}
              </div>
              <div className="w-full flex flex-col gap-4 font-bold mb-[2rem]">
                <h1>Upload Marks Entry Template</h1>
                <p>Upload Your File (xlsx) : {file?.name}</p>
                <div className="flex gap-2">
                  <InputFileButton
                    text={"Browse"}
                    accept={
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }
                    handleSelect={(file) => {
                      setFile(file);
                    }}
                    disable={
                      !id ||
                      subject.length === 0 ||
                      section.length === 0 ||
                      !grade
                    }
                  />

                  <BasicButton
                    text={"Upload"}
                    disable={!file}
                    handleButtonAction={() => {
                      dialogRef.current.openDialog();
                    }}
                  />
                </div>
              </div>
              <div className="w-full flex justify-center">
                <BasicButton
                  text={"Download template"}
                  disable={
                    !id ||
                    subject.length === 0 ||
                    section.length === 0 ||
                    !grade
                  }
                  handleButtonAction={handleDownloadTemplate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExcelMarksEntry;
